<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" sm="6" md="12" class="py-0">
              <v-select
                v-model="tipoDescuentoSelected"
                :items="tiposDescuentos"
                label="Tipo de descuento"
                item-value="id"
                item-text="value"
                :rules="rules.required"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <currency-input
                v-model="sueldoDesde"
                label="Sueldo desde"
                :options="currencyOptions"
                :rules="sueldoDesde === 0 ? [] : rules.required"
              ></currency-input>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <currency-input
                v-model="sueldoHasta"
                label="Sueldo hasta"
                :options="currencyOptions"
                :rules="sueldoHasta === 0 ? [] : rules.required"
              ></currency-input>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="porcentaje"
                label="Porcentaje"
                hint="Porcentaje"
                dense
                outlined
                v-mask="'#####'"
                :rules="validateFormat().concat(rules.required)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="valor"
                :append-icon="porcentajeIcon"
                label="Valor"
                dense
                disabled
                filled
                readonly
                class="theme--light.v-input input"
                outlined
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia desde -->
              <v-menu
                ref="menu"
                v-model="menuDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeTextField"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="formato DD/MM/AAAA"
                    @keydown.tab="menuDesde = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                    @change="
                      [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                    "
                    clearable
                    dense
                    outlined
                    autocomplete="off"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  scrollable
                  @change="fechaDesdeTextField = formatDate(fechaDesde)"
                  @input="menuDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia hasta -->
              <v-menu
                ref="menu2"
                v-model="menuHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaTextField"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    @keydown.tab="menuHasta = false"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    hint="formato DD/MM/AAAA"
                    @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                    @change="
                      [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                    "
                    clearable
                    dense
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  scrollable
                  @change="fechaHastaTextField = formatDate(fechaHasta)"
                  @input="menuHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import { mask } from "vue-the-mask";
import moment from "moment";

export default {
  name: "EditDescuentoAportesYContribuciones",
  props: {
    descuentoId: {
      type: Number,
      required: false,
    },
  },
  components: { CurrencyInput },
  directives: { mask },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDIT_DESCUENTOS_APORTES_CONTRIBUCIONES,
    formNewTitle: enums.titles.NUEVO_DESCUENTOS_APORTES_CONTRIBUCIONES,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    tiposDescuentos: [],
    tipoDescuentoSelected: null,
    sueldoDesde: 0,
    sueldoHasta: 0,
    porcentaje: 0,
    valor: 0,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
    menuDesde: null,
    fechaDesde: null,
    fechaDesdeTextField: null,
    menuHasta: null,
    fechaHasta: null,
    fechaHastaTextField: null,
  }),
  created() {
    if (this.descuentoId != null) {
      this.setDescuento();
    } else {
      this.newDescuento();
    }
    this.setTiposDecuentos();
  },
  watch: {
    porcentaje() {
      this.calcularValorPorcentaje(this.porcentaje);
    },
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getDescuentoAportesContribucionesById:
        "aportes/getDescuentoAportesContribucionesById",
      getTiposDescuentosAportesContribuciones:
        "aportes/getTiposDescuentosAportesContribuciones",
      postDescuentoAportesContribuciones:
        "aportes/postDescuentoAportesContribuciones",
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    async setDescuento() {
      const response = await this.getDescuentoAportesContribucionesById(
        this.descuentoId
      );
      this.tipoDescuentoSelected = response.tipoDescuentoId;
      this.sueldoDesde = response.sueldoDesde;
      this.sueldoHasta = response.sueldoHasta;
      this.porcentaje = response.porcentaje * 100;
      this.fechaDesdeTextField = this.functionFormatDate(response.fechaDesde);
      this.fechaDesde = response.fechaDesde;
      this.fechaHasta = response.fechaHasta;
      this.fechaHastaTextField = this.functionFormatDate(response.fechaHasta);
    },
    async newDescuento() {
      this.formEditTitle = this.formNewTitle;
    },
    async setTiposDecuentos() {
      const tiposDecuentos =
        await this.getTiposDescuentosAportesContribuciones();
      this.tiposDescuentos = tiposDecuentos;
      if (this.tiposDescuentos.length === 1 && this.descuentoId == null) {
        this.tipoDescuentoSelected = this.tiposDescuentos[0].id;
      }
    },
    async saveEdit() {
      const data = {
        descuentoId: this.descuentoId,
        sueldoDesde: this.sueldoDesde,
        sueldoHasta: this.sueldoHasta,
        porcentaje: this.valor,
        tipoDescuentoId: this.tipoDescuentoSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
      };
      const response = await this.postDescuentoAportesContribuciones(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // Función que valida el formato de el campo porcentaje.
    validateFormat() {
      //Variable que indica cantidad de "."
      let dotCount = 0;
      //Variable que indica cantidad de caracteres decimales (despues de la coma)
      let afterComa = 0;
      //Convierto a String el porcentaje
      let arrayString = this.porcentaje.toString();
      //Recorro String de porcentaje
      for (let index = 0; index < arrayString.length; index++) {
        //Asigno la variable temporal
        const element = arrayString[index];
        //Validacion que verifica si hay un caracter despues de la coma, o punto.
        if (dotCount == 1) {
          //Aumento el 'afterComa' porque estoy ubicado despues de una coma o punto
          afterComa++;
        }
        //Valido que existan 2 numero maximos antes de la coma, o punto
        if (dotCount == 0 && index > 5 && element != "." && afterComa == 0) {
          return ["Formato incorrecto"];
        }
        //Si el elemento es un '.' aumento la cantidad de puntos.
        if (element == ".") {
          dotCount++;
          //Valido que si el punto no este ubicado en el inicio o en un indice mayor a 3
          if (index == 0 || index > 5) {
            return ["Formato incorrecto"];
          }
        }
        //Valido que no haya mas de un caracter despues de la coma.
        if (afterComa > 1) {
          return ["Formato incorrecto"];
        }
        //Valido que el elemento no sea un '/' o que la cantidad de '.' sea mayor a uno.
        if (element == "/" || dotCount > 1) {
          return ["Formato incorrecto"];
        }
      }
      return [];
    },
    calcularValorPorcentaje(numero) {
      this.valor = numero / 100;
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
