<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="descuentos"
          class="elevation-1"
          :loading="loading"
          :search="search"
          item-key="descuentoId"
        >
          <template v-slot:[`item.sueldoDesde`]="{ item }">
            <span>{{ addDecimals(item.sueldoDesde) }}</span>
          </template>
          <template v-slot:[`item.sueldoHasta`]="{ item }">
            <span>{{ addDecimals(item.sueldoHasta) }}</span>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item.descuentoId)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar descuento</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteDescuentos(item.descuentoId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar descuento</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="35rem"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditDescuentoAportesYContribuciones
        :descuentoId="descuentoId"
        @closeAndReload="closeAndReload"
      />
    </v-dialog>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import EditDescuentoAportesYContribuciones from "@/components/modules/cuotas/aportes/EditDescuentoAportesYContribuciones.vue";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "DescuentosAportesYContribuciones",
  components: {
    PageHeader,
    GoBackBtn,
    DeleteDialog,
    EditDescuentoAportesYContribuciones,
    Ayuda
  },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    showDeleteModal: false,
    routeToGo: "ConfiguracionDDJJAportes",
    titleDelete: enums.titles.DELETE_DESCUENTOS_APORTES_CONTRIBUCIONES,
    search: "",
    descuentos: [],
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.DESCUENTOS_APORTES_CONTRIBUCIONES,
    descuentoId: null,
    searchIcon: enums.icons.SEARCH,
    title: enums.titles.DESCUENTOS_APORTES_CONTRIBUCIONES,
    rules: rules,
    headers: [
      {
        text: "Tipo de descuento",
        align: "start",
        value: "tipoDescuentoNom"
      },
      {
        text: "Sueldo desde",
        align: "end",
        value: "sueldoDesde"
      },
      {
        text: "Sueldo hasta",
        align: "end",
        value: "sueldoHasta"
      },
      {
        text: "Porcentaje",
        align: "end",
        value: "porcentaje"
      },
      {
        text: "Fecha desde",
        align: "start",
        value: "fechaDesde"
      },
      {
        text: "Fecha hasta",
        align: "start",
        value: "fechaHasta"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],

    allowedActions: null,
    loading: false,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadDescuentos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getDescuentosAportesContribuciones:
        "aportes/getDescuentosAportesContribuciones",
      deleteDescuentoAportesContribuciones:
        "aportes/deleteDescuentoAportesContribuciones"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_DESCUENTO:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_DESCUENTO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_DESCUENTO:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadDescuentos() {
      this.loading = true;
      const response = await this.getDescuentosAportesContribuciones();
      if (response.status === 200) {
        this.loading = false;
        this.descuentos = response.data.data;
      }
    },
    openModal(id) {
      this.openModalEdit = true;
      this.descuentoId = id;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadDescuentos();
    },
    closeModal() {
      this.openModalEdit = false;
    },
    deleteDescuentos(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteDescuentoAportesContribuciones(
        this.idToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadDescuentos();
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
